/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 /*jslint eqeq: true*/

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.testimonial-carousel').slick({
          dots: true,
          arrows: true,
          autoplay: true,
          speed: 700,
          autoplaySpeed: 5000,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                arrows: false,
                dots: true
              }
            }
          ]
        });

        $('.employee-carousel').slick({
          dots: true,
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            }
          ]

        });

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $(".hamburger").removeClass('is-active');
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });
        $('.scrollup').click(function () {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });

        var screenSize;

        function checkSize() {
          if($('.test-size').css('font-size') === '1px') {
            screenSize = 'xs';
          } else if($('.test-size').css('font-size') === '2px') {
            screenSize = 'sm';
          } else if($('.test-size').css('font-size') === '3px') {
            screenSize = 'md';
          } else if($('.test-size').css('font-size') === '4px') {
            screenSize = 'lg';
          }
        }

        checkSize();

        $(window).resize(function(){
          checkSize();
        });

        function isScrolledIntoView(elem)
        {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();
            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop) && docViewTop !== 0);
        }

        var showSwipe1 = false;
        var showSwipe2 = false;

        $(window).scroll(function() {
          if ($(this).scrollTop() > 100) {
              $('.scrollup').fadeIn();
          } else {
              $('.scrollup').fadeOut();
          }

          if($('.swipicon-container.first').length) {
            if(isScrolledIntoView($('.swipicon-container.first')) && !$('.swipicon-container.first').find('.swipicon').hasClass('swipiconActive') && !showSwipe1 && (screenSize == 'xs' || screenSize == 'sm')) {
              showSwipe1 = true;
              $('.swipicon-container.first').find('.swipicon').fadeIn(1500,function(){
                $('.swipicon-container.first').find('.swipicon').addClass('swipiconActive');
              }).delay(1600).fadeOut(1500);
            }
          }

          if($('.swipicon-container.second').length) {
            if(isScrolledIntoView($('.swipicon-container.second')) && !$('.swipicon-container.second').find('.swipicon').hasClass('swipiconActive') && !showSwipe2 && (screenSize == 'xs' || screenSize == 'sm')) {
              showSwipe2 = true;
              $('.swipicon-container.second').find('.swipicon').fadeIn(1500,function(){
                $('.swipicon-container.second').find('.swipicon').addClass('swipiconActive');
              }).delay(1600).fadeOut(1500);
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'contact': {
      init: function() {

        function initialize_gmap(map_lat, map_long, map_radius, map_zoom) {

            var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

            var isDraggable = w > 767 ? true : false;
            // Create the map.
            var map = new google.maps.Map(document.getElementById('map_canvas'), {
              zoom: map_zoom,
              scrollwheel: false,
              draggable: isDraggable,
              center: {lat: map_lat, lng: map_long},
              mapTypeId: google.maps.MapTypeId.CITY
            });

              // Add the circle for this city to the map.
            var bangorCircle = new google.maps.Circle({
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: '#FF0000',
              fillOpacity: 0.2,
              map: map,
              center: {lat: map_lat, lng: map_long},
              radius: map_radius
            });

            var center;
            function calculateCenter() {
              center = map.getCenter();
            }
            google.maps.event.addDomListener(map, 'idle', function() {
              calculateCenter();
            });
            google.maps.event.addDomListener(window, 'resize', function() {
              map.setCenter(center);
            });

        }

        if($('#map_canvas').length) {
          var map_lat = parseFloat($('.map-data .map_lat').data('maplat'));
          var map_long = parseFloat($('.map-data .map_long').data('maplong'));
          var map_radius = parseFloat($('.map-data .map_radius').data('mapradius'));
          var map_zoom = parseFloat($('.map-data .map_zoom').data('mapzoom'));
          initialize_gmap(map_lat, map_long, map_radius, map_zoom);
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
